import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { IEventDiscount, EventService } from '../../../../../shared/service/backend/event.service';
import { HallService } from '../../../../../shared/service/backend/hall.service';
import { WebSocketService } from '../../../../../shared/service/web-socket.service';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { BinService } from '../../../../../shared/service/rx/bin.service';
import { ColorPriceService } from '../../../../../shared/service/backend/color-price.service';
import { UserService } from '../../../../../shared/service/backend/user.service';
import { Config } from '../../../../../shared/config/config';
import { Bin } from '../../../../../shared/models/Bin';
import { TicketOrderWithOutSchemDto } from '../../../../../shared/models/dto/TicketOrderWithOutSchemDto';
import { ColorPrice } from '../../../../../shared/models/color-price';
import { TicketGroupWithOutSachem } from '../../../../../shared/models/TicketGroupWithOutSachem';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { TimerBuilder } from '../../../../../shared/service/utils/timer.builder';

import { ClientEventOneBaseDataDto } from '../../../../../shared/models/dto/ClientEventOneBaseDataDto';
import { Seat } from '../../../../../shared/models/Seat';
import { Event } from '../../../../../shared/models/Event';
import { Ticket } from '../../../../../shared/models/Ticket';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { openCloseElementBlock, openCloseElementFlex } from '../../../../../shared/service/utils/export-functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BinOrderPayBoxOfficeRequestDto } from '../../../../../shared/models/dto/BinOrderPayBoxOfficeRequestDto';
import { TypeOfPayment } from '../../../../../shared/models/enums/enums';
import { PdfService } from '../../../../../shared/service/backend/pdf.service';
import { BinPriceWinCommissionDto } from '../../../../../shared/models/dto/BinPriceWinCommissionDto';
import { ComponentCanDeactivate } from '../../../../../shared/can-de-activate/update/update.can-de-activate';
import { SideInfo, SideInfoRxService } from '../../../../source/side-info-bar/side-info-bar.component';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';
import { tap } from 'rxjs/operators';
import { IBin, IBinTicket, IOnSelectTicketEvent, IPartHall, ITicketGrop } from '../event-action/event-action.component';
import { UniversalBinService } from 'src/shared/service/backend/universal-bin.service';
import { AbonementService } from 'src/shared/service/backend/abonement.service';
import { BoxOfficeEventDto } from 'src/shared/models/dto/BoxOfficeEventDto';

export interface IAbonement {
  colorPrice: ColorPrice;
  event: ClientEventOneBaseDataDto;
  id: number;
  used: boolean;
  abonementCode: string;
}

export interface IFinishAbonementBinPayload {
  binId: number;
  ticketAbonementDto: {
    abonementCode: number;
    ticketId: number;
  }[];
}
export interface IAbonementEvent {
  abonementId: number;
  event: ClientEventOneBaseDataDto;
  tickets: (IBinTicket & { abonementId: number; abonementCode: number })[];
  eventId: number;
  binId: number;
}

@Component({
  selector: 'app-event-buy',
  templateUrl: './event-buy.component.html',
  styleUrls: ['./event-buy.component.css'],
  providers: [EventService, BinService, HallService, ColorPriceService, BookingService, UserService, PdfService],
})
export class EventBuyComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  event: ClientEventOneBaseDataDto = new ClientEventOneBaseDataDto();
  myColor1: string = Config.MY_SELECTED_SEAT;

  hoveredTicket$: Subject<{
    ticketPosition: string;
    ticketRow: string;
    ticketSector: string;
    hoveredTicket: any;
    fanzoneSeats: number | null;
    pageX: number;
    pageY: number;
  } | null> = new Subject();

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();
  bin: Bin = new Bin();
  routerGo: boolean = false;
  eventId: number;
  hallId: number;
  tickets: Ticket[] = [];
  ticketsDto: TicketOrderWithOutSchemDto[] = [];
  partHall: IPartHall;
  colorPricesList: ColorPrice[] = [];
  orderString: string = '';
  checkWithSheme: boolean;
  ticketGroupWithoutSchema: (ITicketGrop & { eventsCount?: number })[] = [];
  EMAIL_PATTERN = Config.EMAIL_MASK;
  PHONE_PATTERN = Config.PHONE_MASK;
  sum: number = 0;
  lang: string;
  date: Date = new Date();
  mainBookedForm: FormGroup;
  wayStepRange: number = -20;
  anonBin: boolean = false;
  priceCommission: BinPriceWinCommissionDto = new BinPriceWinCommissionDto();
  startBought: boolean = false;
  typePay: TypeOfPayment;
  validEmail: boolean = false;
  ticketsForPrint: number[] = [];
  ticketsForPrinted = new Set();
  promoCode = '';
  promoCodePrice = 0;
  isActivePromoCode = false;
  selectType: 'seat' | 'row' | 'sector' = 'seat';

  eventDiscounts: (IEventDiscount & { exludedPrices: number[] })[] = [];
  isSameEventDiscountExcludes: boolean = false;

  binInfo: { id: string; lifeTime: number };
  newBin: IBin;

  tempAbonementBinId: number;
  abonementId: number;
  abonementCode: string;
  availableAbonements: { abonements: IAbonement[]; maxAbonementPrice: number } = { abonements: [], maxAbonementPrice: 0 };
  pickedAbonements: IAbonementEvent[] = [];
  availableEventIds: number[] = [];
  isAbonementsCreating = false;
  currentEventAbonementIndex = 0;
  pickedEventCount = 0;
  isAvailableAbonementCodesShow = false;
  finishBinAbonementEventsId: number;

  timeBuilder = new TimerBuilder();

  additionalEvents: (BoxOfficeEventDto & { selected?: boolean })[] = [];
  additionalEventsCount: number;
  currentAdditionalEventIndex = 0;
  selectedAdditionalEventsCount = 0;
  ticketId: number;

  disableSectorNames: string[] = [];

  subSolidLock: Subscription;
  subSolidUnlock: Subscription;
  subWithoutLock: Subscription;
  subWithoutUnlock: Subscription;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private activatedRouter: ActivatedRoute,
    private userDetailsService: UserDetailsService,
    private loaderService: MainLoaderService,
    private webSocket: WebSocketService,
    private eventService: EventService,
    private binService: BinService,
    private hallService: HallService,
    private colorPriceService: ColorPriceService,
    private bookingService: BookingService,
    private pdfService: PdfService,
    private sideInfoRxService: SideInfoRxService,
    private uBinService: UniversalBinService,
    private abonementService: AbonementService,
  ) {
    this.abonementId = this.activatedRouter.snapshot.queryParams['abonement'];
    this.lang = translateService.currentLang;
    this.translateService.onLangChange.subscribe(next => {
      this.lang = next.lang;
    });
    this.user = this.userDetailsService.getUser();
    this.userDetailsService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.bin = this.binService.bin;
    this.binService.bin$.subscribe(
      next => {
        this.bin = next;
        this.bin.user = new UserWithBoxOfficeDto();
        // if (this.bin.ticketList.length == 0) {
        // if (this.timeBuilder) {
        //   this.timeBuilder.destroy();
        // }
        // this.orderString = '';
        // this.wayStepRange = -20;
        // } else
        if (this.bin.ticketList.length == 1) {
          document.getElementById('binTop').classList.add('display_block');
        }
        if (this.orderString != '') {
          this.bookingService.clientPriceWithBookedCommission(this.orderString).subscribe(
            next => {
              if (this.bin.ticketList && next.ticketsList) {
                this.bin.ticketList.forEach(el => (el.finalPrice = next.ticketsList.find(n => n.id == el.id).finalPrice));
              }
              this.priceCommission = next;
            },
            error => {
              console.error(error);
            },
          );
        }
      },
      error => {
        console.error(error);
      },
    );
    this.bin.user = new UserWithBoxOfficeDto();
    this.activatedRouter.params.subscribe(
      param => {
        this.activatedRouter.queryParams.subscribe(
          () => {
            if (this.eventId !== +param['id']) {
              this.eventId = +param['id'];
              this.orderString = '';
              this.newBin = null;
            }
            this.eventService.findOnePublicEventParseClientEventOneBaseDataDto(this.eventId).subscribe(
              next => {
                if (next.isDiscountAvailable) {
                  this.eventService.getEventDiscounts(next.id).subscribe(res => {
                    this.isSameEventDiscountExcludes = res.every(el => el.excludedColorPrices === res[0].excludedColorPrices);
                    this.eventDiscounts = res.map(el => {
                      return {
                        ...el,
                        exludedPrices: el.excludedColorPrices
                          .split(',')
                          .map(id => el.colorPriceList.find(colorPrice => colorPrice.id === +id).price),
                      };
                    });
                  });
                }
                this.event = next;
                this.sum = this.binService.sum;
                this.binService.sum$.subscribe(
                  next => {
                    this.sum = next;
                  },
                  error => {
                    this.loaderService.endLoader();
                    console.error(error);
                  },
                );
                //
                this.date = new Date(this.event.dataBegin);
              },
              error => {
                this.loaderService.endLoader();
                console.error(error);
              },
            );

            this.webSocket.initializeWebSocketConnection(this.eventId);
            this.hallService.findOneHallByEvent(this.eventId).subscribe(
              next => {
                if (!next) {
                  this.partHall = null;
                  this.checkWithSheme = true;
                  this.loaderService.startLoader();
                  this.bookingService.onlinefindAllFreeTicketByEventIdWithOutSchema(this.eventId).subscribe(
                    tickets => {
                      this.ticketsDto = tickets;
                      this.eventService.getTicketGroups(this.eventId).subscribe(
                        next => {
                          this.ticketGroupWithoutSchema = next;
                          if (this.event.abonementEvent) {
                            this.abonementService.getAbonementPrice(this.eventId).subscribe(res => {
                              this.ticketGroupWithoutSchema = this.ticketGroupWithoutSchema.map(el => {
                                el.eventsCount = res.find(abonColorPrice => abonColorPrice.colorPrice.id === el.colorPrice.id).eventsCount;
                                return el;
                              });
                            });
                          }
                          this.loaderService.endLoader();

                          this.subWithoutLock = this.webSocket.lockWebSocketData$.subscribe(
                            next => {
                              this.ticketsDto.splice(this.getIndexOfArray(this.ticketsDto, next.ticketId), 1);
                              this.loaderService.endLoader();
                            },
                            error => {
                              this.loaderService.endLoader();
                              console.error(error);
                            },
                          );
                          var aase = 0;
                          setInterval(() => {
                            aase = aase + 1;
                          }, 1000);
                          this.subWithoutUnlock = this.webSocket.unLockWebSocketData$.subscribe(
                            next => {
                              if (this.binService.searchTicketByIdWithout(next.ticketId, this.ticketsDto)) {
                                let ticket: TicketOrderWithOutSchemDto = new TicketOrderWithOutSchemDto();
                                ticket.id = next.ticketId;
                                ticket.colorPrice = new ColorPrice();
                                ticket.colorPrice.id = next.colorPrice;
                                ticket.ticketGroupWithOutSachem = new TicketGroupWithOutSachem();
                                ticket.ticketGroupWithOutSachem.id = next.seatId;
                                this.ticketsDto.push(ticket);
                              }

                              this.binService.rmTicketInBin(next.ticketId, this.orderString);
                            },
                            error => {
                              this.loaderService.endLoader();
                              console.error(error);
                            },
                          );
                          this.initAbonementData();

                          this.loadBin(true);
                        },
                        error => {
                          this.loaderService.endLoader();
                          console.error(error);
                        },
                      );
                    },
                    error => {
                      this.loaderService.endLoader();
                      console.error(error);
                    },
                  );
                } else {
                  this.checkWithSheme = false;
                  this.hallId = next.id;
                  this.loaderService.startLoader();

                  this.hallService.findFirstPartHall(next.id).subscribe(
                    next => {
                      this.partHall = next;
                      // this.render = 'ready';
                      if (this.partHall.type == 'SOLID_PART') {
                        this.bookingService.getAllBookedTicketsInPartHall(this.eventId, next.id).subscribe(
                          t => {
                            this.setInfoBookedToTicket(t);
                          },
                          error => {
                            console.error(error);
                          },
                        );
                        this.bookingService.getAllPayedTicketsInPartHall(this.eventId, next.id).subscribe(
                          t => {
                            this.setInfoBoughtToTicket(t);
                          },
                          error => {
                            console.error(error);
                          },
                        );
                        this.bookingService.getAllAvailableTicketsInPartHall(this.eventId, next.id).subscribe(
                          tickets => {
                            this.tickets = tickets;
                            this.paintSeat(tickets);
                            this.colorPriceService.getColorPriceForSchema(this.eventId, next.id).subscribe(
                              next => {
                                this.colorPricesList = next;
                                this.initAbonementData();
                                this.loaderService.endLoader();
                                this.loadBin(true);
                              },
                              error => {
                                this.loaderService.endLoader();
                                console.error(error);
                              },
                            );

                            this.subSolidLock = this.webSocket.lockWebSocketData$.subscribe(
                              next => {
                                // console.log('lock');
                                const ticket = this.tickets.find(el => el.id === next.ticketId);
                                if (ticket?.seat && !ticket.seat.fanzone) {
                                  document.getElementById(ticket.seat.key).style.fill =
                                    next.order === this.orderString || this.pickedAbonements.map(el => el.binId).includes(+this.orderString)
                                      ? Config.MY_SELECTED_SEAT
                                      : Config.DISABLE_SEAT;
                                }
                                this.tickets.splice(this.getIndexOfArray(this.tickets, next.ticketId), 1);
                                this.loaderService.endLoader();
                              },
                              error => {
                                this.loaderService.endLoader();
                                console.error(error);
                              },
                            );
                            this.subSolidUnlock = this.webSocket.unLockWebSocketData$.subscribe(
                              next => {
                                console.log('unlock');
                                const ticket: Ticket = new Ticket();
                                ticket.id = next.ticketId;
                                ticket.colorPrice = this.colorPricesList.find(el => el.id === next.colorPrice) || new ColorPrice();
                                ticket.event = new Event();
                                ticket.event.id = next.eventId;
                                ticket.seat = new Seat();
                                ticket.seat.id = next.seatId;
                                ticket.seat.key = next.seatKey;
                                ticket.seat.fanzone = next.fanzone;
                                this.tickets.push(ticket);
                                document.getElementById(ticket.seat.key).style.fill = this.getColorPriceById(next.colorPrice).color;
                                // this.binService.rmTicketInBin(next.ticketId, 'order' + this.eventId);
                                this.loaderService.endLoader();
                              },
                              error => {
                                this.loaderService.endLoader();
                                console.error(error);
                              },
                            );
                          },
                          error => {
                            this.loaderService.endLoader();
                            console.error(error);
                          },
                        );
                      } else if (this.partHall.type == 'MAIN_PART') {
                      }
                    },
                    error => {
                      this.loaderService.endLoader();
                      console.error(error);
                    },
                  );
                }
              },
              error => {
                this.loaderService.endLoader();
                console.error(error);
              },
            );
          },
          error => {
            this.loaderService.endLoader();
            console.error(error);
          },
        );
      },
      error => {
        this.loaderService.endLoader();
        console.error(error);
      },
    );
  }

  ngOnInit() {
    this.mainBookedForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[A-zА-яЁёіІїЇ']+$/)]),
      lastName: new FormControl('', [Validators.pattern(/^[A-zА-яЁёіІїЇ']+$/)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(18)]),
      email: new FormControl('', [Validators.pattern(this.EMAIL_PATTERN)]),
    });
  }

  makeSectionsGrey(sectorName, isLastElement: boolean) {
    if (this.partHall.multiHall) {
      if (!this.disableSectorNames.includes(sectorName)) {
        this.disableSectorNames.push(sectorName);
      }
      if (isLastElement) {
        Array.from(document.querySelectorAll('[sector-link]'))
          .filter(el => !this.disableSectorNames.includes(el.getAttribute('sector-link')))
          .forEach(el => ((el as HTMLElement).style.filter = 'grayscale(1)'));
      }
    }
  }

  closeAdditionalEvents() {
    this.removeTicketFromBin(this.newBin.ticketList.find(el => el.id === this.ticketId));
    this.currentAdditionalEventIndex = 0;
    this.selectedAdditionalEventsCount = 0;
    this.additionalEvents = [];
  }

  completeAdditionEventSelection() {
    this.abonementService
      .setSelectedAdditionalEvents(
        this.additionalEvents.filter(el => el.selected).map(el => el.id),
        this.ticketId,
      )
      .subscribe(res => {
        this.currentAdditionalEventIndex = 0;
        this.selectedAdditionalEventsCount = 0;
        this.additionalEvents = [];
      });
  }
  selectAdditionEvent() {
    this.additionalEvents[this.currentAdditionalEventIndex].selected = !this.additionalEvents[this.currentAdditionalEventIndex].selected;
    this.selectedAdditionalEventsCount = this.additionalEvents.filter(el => el.selected).length;
  }

  switchAdditionalEvents(next?) {
    if (next && this.currentAdditionalEventIndex < this.additionalEvents.length - 1) {
      this.currentAdditionalEventIndex++;
    }
    if (!next && this.currentAdditionalEventIndex > 0) {
      this.currentAdditionalEventIndex--;
    }
  }
  getAdditionalEvents(event: { eventsCount: number; ticketId: number; minTicketPrice: number }) {
    this.ticketId = event.ticketId;
    this.additionalEventsCount = event.eventsCount;
    this.bookingService
      .filterEventsBoxOfficeStatisticByDate(
        20,
        0,
        'ALL',
        undefined,
        undefined,
        this.event.eventCategory[0].id,
        undefined,
        '',
        '',
        'ASC',
        false,
      )
      .subscribe(res => {
        this.additionalEvents = res.content.filter(el => !el.abonementEvent && el.minPrice <= event.minTicketPrice);
      });
  }

  finishAbonementBin() {
    this.abonementService
      .finishAbonementBin(
        this.abonementCode
          ? [{ binId: this.newBin[0].id, ticketAbonementDto: [{ ticketId: this.newBin[0].id, abonementCode: +this.abonementCode }] }]
          : this.pickedAbonements.map(el => {
              return {
                binId: el.binId,
                ticketAbonementDto: el.tickets.map(ticket => {
                  return { abonementCode: ticket.abonementCode, ticketId: ticket.id };
                }),
              };
            }),
      )
      .subscribe(res => {
        const user = res[0].user;

        this.abonementService.printTickets(
          res.map(el => el.id),
          user ? user.uuid : JSON.parse(localStorage.getItem('user'))?.uuid,
        );
        localStorage.removeItem('pickedAbonements');
        localStorage.removeItem('abonementEventIds');
        localStorage.removeItem('abonementsBinsTime');
        window.location.href = `${window.location.origin}`;
      });
  }

  nextAbonementEvent() {
    this.redirect(
      (window.location.href = `${window.location.origin}/#/buy/${this.availableEventIds[this.currentEventAbonementIndex + 1]}?abonement=${
        this.abonementId
      }`),
    );
  }
  previousAbonementEvent() {
    this.redirect(
      `${window.location.origin}/#/buy/${this.availableEventIds[this.currentEventAbonementIndex + -1]}?abonement=${this.abonementId}`,
    );
  }

  redirect(url: string) {
    this.loaderService.startLoader();
    setTimeout(() => {
      window.location.href = url;
      window.location.reload();
    }, 100);
  }

  setPickedAbonementEvents(tickets: IBinTicket[]) {
    if (this.availableAbonements.abonements.length) {
      this.pickedAbonements = this.pickedAbonements.map(el =>
        el.eventId === this.eventId
          ? {
              event: this.event,
              tickets: this.mapAbonementTicketsByAvailablePrices(tickets),
              eventId: this.eventId,
              binId: +this.orderString,
              abonementId: this.abonementId,
              available: this.availableAbonements.abonements.length - this.mapAbonementTicketsByAvailablePrices(tickets).length,
            }
          : el,
      );

      this.pickedEventCount = this.pickedAbonements.filter(el => el && el.tickets.length).length;
      this.currentEventAbonementIndex = this.availableEventIds.indexOf(this.eventId);
      sessionStorage.setItem('pickedAbonements', JSON.stringify(this.pickedAbonements));
    }
  }

  loadAbonementBin() {
    const abonementsBinsTime = (new Date(sessionStorage.getItem('abonementsBinsTime')).valueOf() - Date.now()) / 1000;
    if (abonementsBinsTime && abonementsBinsTime < 0) {
      this.bin = new Bin();
      this.bin.user = new UserWithBoxOfficeDto();
      this.newBin = null;
      this.orderString = '';
      sessionStorage.removeItem('previousBinInfo');
      sessionStorage.removeItem('abonementEventIds');
      sessionStorage.removeItem('pickedAbonements');
      sessionStorage.removeItem('abonementsBinsTime');
    }
    const pickedAbonements = JSON.parse(sessionStorage.getItem('pickedAbonements'));
    if (pickedAbonements && !this.orderString) {
      const binId = (this.orderString = pickedAbonements.find(el => {
        return el.eventId === this.eventId;
      }).binId);
      this.orderString = binId ? binId.toString().padStart(15, '0') : '';
    }
    this.pickedAbonements = this.pickedAbonements?.length ? this.pickedAbonements : pickedAbonements;
    if (this.orderString) {
      this.uBinService.getBin('BOX_OFFICE', this.orderString, this.promoCode).subscribe(bin => {
        if (this.partHall) {
          // this.pickedAbonements
          //   .find(el => el.eventId === this.eventId)
          // .tickets.forEach(ticket => {
          bin.ticketList.forEach(ticket => {
            const seat = document.getElementById(ticket.seat.key);
            seat.style.fill = this.myColor1;
            seat.setAttribute('seatId', String(ticket.seat.id));
            seat.setAttribute('ticketId', String(ticket.id));
          });
        }

        this.timeBuilder = new TimerBuilder()
          .setSecond(abonementsBinsTime || this.event.ticketOrderTimeBoxOffice)
          .onEnd(() => {
            this.bin = new Bin();
            this.bin.user = new UserWithBoxOfficeDto();
            this.newBin = null;
            this.orderString = '';
            sessionStorage.removeItem('previousBinInfo');
            sessionStorage.removeItem('abonementEventIds');
            sessionStorage.removeItem('pickedAbonements');
            sessionStorage.removeItem('abonementsBinsTime');
          })
          .buildFromNow();

        this.newBin = bin;
        this.recalculateAvailableAbonements();
        this.setPickedAbonementEvents(this.newBin.ticketList);
      });
    }
  }

  mapAbonementTicketsByAvailablePrices(tickets: IBinTicket[]): (IBinTicket & { abonementId: number; abonementCode: number })[] {
    if (this.availableAbonements.abonements.length) {
      const selectedAvailableAbonements = [];
      const currentAvailableAbonements = () =>
        this.availableAbonements.abonements.filter(el => !selectedAvailableAbonements.includes(el.id));

      return tickets.map(ticket => {
        {
          const minPositiveValue = Math.min.apply(
            Math,
            currentAvailableAbonements()
              .map(ab => ab.colorPrice.price)
              .filter(abonementPrice => abonementPrice - ticket.colorPrice.price >= 0),
          );
          const calculatedAbonement = currentAvailableAbonements().find(el => el.colorPrice.price === minPositiveValue);
          selectedAvailableAbonements.push(calculatedAbonement.id);

          return {
            ...ticket,
            abonementId: calculatedAbonement.id,
            abonementCode: +calculatedAbonement.abonementCode,
          };
        }
      });
    }
  }

  recalculateAvailableAbonements() {
    if (this.availableAbonements.abonements.length) {
      const selectedAvailableAbonements = [];
      const currentAvailableAbonements = () =>
        this.availableAbonements.abonements.filter(el => !selectedAvailableAbonements.includes(el.id));
      (this.newBin?.ticketList || []).forEach(ticket => {
        const minPositiveValue = Math.min.apply(
          Math,
          currentAvailableAbonements()
            .map(ab => ab.colorPrice.price)
            .filter(abonementPrice => abonementPrice - ticket.colorPrice.price >= 0),
        );
        selectedAvailableAbonements.push(currentAvailableAbonements().find(el => el.colorPrice.price === minPositiveValue).id);
      });
      this.availableAbonements.maxAbonementPrice = Math.max.apply(
        Math,
        currentAvailableAbonements().map(el => el.colorPrice.price),
      );

      this.availableAbonements = JSON.parse(JSON.stringify(this.availableAbonements));
    }
  }

  initAbonementData() {
    const abonementId = this.activatedRouter.snapshot.queryParams['abonement'];
    const abonementCode = this.activatedRouter.snapshot.queryParams['abonementCode'];

    if (abonementId || abonementCode) {
      this.isAbonementsCreating = true;

      const getAbonements = abonementId
        ? this.abonementService.getAbonementsByBin(abonementId.toString())
        : this.abonementService.getActiveAbonementsByAbonementCode(abonementCode, this.eventId.toString());

      getAbonements.subscribe(res => {
        if (!res.length || !res[0]) {
          alert('Абонемент використано');
          this.redirect('window.location.origin');
          return;
        }

        const currentEventAvailableAbonements = res.filter(el => el.event.id === this.eventId && el.used === false);
        const availableAbonements = res.filter(el => el.used === false);
        if (!currentEventAvailableAbonements.length && abonementId) {
          alert('Абонемент на данну подію використано, переходимо до доступної події');
          this.redirect(`${window.location.origin}/#/buy/${availableAbonements[0].event.id}?abonement=${abonementId}`);
        }

        if (res[0].used === true && abonementCode) {
          alert('Абонемент код на цю подію використано');
          this.redirect(window.location.origin);
        }

        this.abonementId = abonementId;
        this.abonementCode = abonementCode;
        this.availableEventIds = Array.from(new Set(availableAbonements.map(el => el.event.id))).sort();

        this.availableAbonements.abonements = currentEventAvailableAbonements;
        this.availableAbonements.maxAbonementPrice = Math.max.apply(
          Math,
          this.availableAbonements.abonements.map(el => el.colorPrice.price),
        );

        const savedPickedAbonements = JSON.parse(sessionStorage.getItem('pickedAbonements'));
        this.pickedAbonements =
          savedPickedAbonements && savedPickedAbonements.length && savedPickedAbonements[0].abonementId === this.abonementId
            ? savedPickedAbonements
            : this.availableEventIds.map(el => {
                return { tickets: [], eventId: el, event: this.event, abonementId: this.abonementId };
              });
        this.recalculateAvailableAbonements();
        this.setPickedAbonementEvents(this.newBin?.ticketList || []);
        this.isAbonementsCreating = false;
      });
    }
  }

  goToAbonementEvents() {
    sessionStorage.removeItem('abonementEventIds');
    sessionStorage.removeItem('pickedAbonements');
    sessionStorage.removeItem('abonementsBinsTime');
    this.bookingService
      .filterEventsBoxOfficeStatisticByDate(
        20,
        0,
        'ALL',
        undefined,
        undefined,
        this.event.eventCategory[0].id,
        undefined,
        undefined,
        undefined,
        'ASC',
        false,
      )
      .subscribe(res => {
        const abonementEventIds = res.content
          .filter(ev => !ev.abonementEvent)
          .map(ev => ev.id)
          .sort();
        sessionStorage.setItem('abonementEventIds', JSON.stringify(abonementEventIds));
        window.location.href = `${window.location.origin}/#/buy/${this.finishBinAbonementEventsId}?abonement=${+this.orderString}`;
        this.orderString = '';
        sessionStorage.removeItem('previousBinInfo');
        window.location.reload();
      });
  }

  setInfoBookedToTicket(tickets: Ticket[]) {
    for (let i = 0; i < tickets.length; i++) {
      document.getElementById(tickets[i].seat.key).setAttribute('bookNow', true + '');
    }
  }

  setInfoBoughtToTicket(tickets: Ticket[]) {
    for (let i = 0; i < tickets.length; i++) {
      document.getElementById(tickets[i].seat.key).setAttribute('boughtNow', true + '');
    }
  }

  private getFanZoneFreeSeats(id: number): number {
    return this.tickets.filter(ticket => ticket.seat.id === id).length;
  }

  private hideInfoAboutSeat(): void {
    this.hoveredTicket$.next(null);
  }

  paintSeat(tickets: Ticket[]) {
    for (let i = 0; i < tickets.length; i++) {
      const seat = document.getElementById(tickets[i].seat.key);
      seat.style.fill = tickets[i].colorPrice.color;
      seat.setAttribute('seatId', tickets[i].seat.id + '');
      seat.setAttribute('price', tickets[i].colorPrice.price + '');
      // seat.setAttribute('sectorName', tickets[i].seat.row.sector.name + '');
      // seat.setAttribute('rowPosition', tickets[i].seat.row.name + '');
      if (tickets[i].seat.fanzone) {
        seat.setAttribute('fanzone', 'true');
      } else {
        seat.setAttribute('ticketId', tickets[i].id + '');
        seat.setAttribute('fanzone', 'false');
      }

      this.makeSectionsGrey(seat.parentElement.parentElement.getAttribute('sector_name'), tickets.length === i + 1);
    }
  }

  backToMainPart() {
    this.routerGo = false;
    this.router.navigateByUrl('/buy/' + this.event.id);
  }

  addEventListenerToMainPart(e) {
    this.routerGo = true;
    this.router.navigateByUrl(
      '/buy/' + this.eventId + '/' + this.eventId + '/' + this.hallId + '/' + (<HTMLElement>e.target).getAttribute('section_name'),
    );
  }

  getIndexOfArray(array: any[], one: number): number {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == one) {
        return i;
      }
    }
    return -1;
  }

  getColorPriceById(id: number): ColorPrice {
    for (let i = 0; i < this.colorPricesList.length; i++) {
      if (this.colorPricesList[i].id == id) {
        return this.colorPricesList[i];
      }
    }
    return null;
  }

  openCloseBinFlex(el: HTMLElement) {
    openCloseElementFlex(el);
  }

  openCloseBinBlock(el: HTMLElement) {
    openCloseElementBlock(el);
  }

  prevStep() {
    if (this.wayStepRange == 0) {
      this.wayStepRange -= 20;
    } else if (this.wayStepRange >= -60) {
      this.wayStepRange += 20;
    }
  }

  changePermissionToMove(i: number, bool?: boolean) {
    if (this.newBin.ticketList.length > 0) {
      this.changeWay(i);
    } else {
      if (bool) {
        this.changeWay(0);
      }
    }
  }

  changeWay(way: number) {
    if (way == 1) {
      this.wayStepRange -= 20;
    } else {
      this.wayStepRange += 20;
      if (this.wayStepRange == 0 && this.orderString != '') {
        this.bookingService.clientPriceWithBookedCommission(this.orderString).subscribe(
          next => {
            this.priceCommission = next;
          },
          error => {
            console.error(error);
          },
        );
      }
    }
  }

  addNamedToTicket(one: Ticket, form: HTMLFormElement) {
    if (form.elements['firstName'].value && form.elements['lastName'].value) {
      one.firstName = form.elements['firstName'].value;
      one.lastName = form.elements['lastName'].value;
      this.openCloseBinBlock(form);
      form.elements['firstName'].classList.toggle('ng-invalid');
      form.elements['firstName'].classList.toggle('ng-touched');
      form.elements['lastName'].classList.toggle('ng-invalid');
      form.elements['lastName'].classList.toggle('ng-touched');
    } else {
      form.elements['firstName'].classList.toggle('ng-invalid');
      form.elements['firstName'].classList.toggle('ng-touched');
      form.elements['lastName'].classList.toggle('ng-invalid');
      form.elements['lastName'].classList.toggle('ng-touched');
    }
  }

  sendBookedBin() {
    if (this.mainBookedForm.valid) {
      this.loaderService.startLoader();
      let tempBin: BinOrderPayBoxOfficeRequestDto = new BinOrderPayBoxOfficeRequestDto();
      tempBin.typeOfPayment = 'NONE';
      this.bin.ticketList.forEach(value => {
        if (value.firstName && value.lastName) {
          tempBin.tickets.push(value);
        }
      });
      tempBin.user = this.bin.user as UserWithBoxOfficeDto;
      tempBin.order = this.orderString;
      tempBin.comment = '';
      this.bookingService.clientOrderBookedBin(tempBin).subscribe(
        next => {
          this.orderString = '';
          this.loaderService.endLoader();
          this.sideInfoRxService.add(new SideInfo().setInfo('Замовлення успішно заброньовано!').setDate());
          this.router.navigateByUrl('/');
        },
        error => {
          this.loaderService.endLoader();
          this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка!').setDate().setError(''));
          console.error(error);
        },
      );
    } else {
      this.sideInfoRxService.add(new SideInfo().setInfo('Заповніть дані про користувача!').setDate().setError(''));
    }
  }

  sendBuyBinAlert(type: TypeOfPayment) {
    this.typePay = type;
    this.startBought = true;
  }

  sendBuyBin() {
    this.loaderService.startLoader();
    let tempBin: BinOrderPayBoxOfficeRequestDto = new BinOrderPayBoxOfficeRequestDto();
    tempBin.typeOfPayment = this.typePay;
    tempBin.order = this.orderString;
    this.bin.ticketList.forEach(value => {
      if (value.firstName && value.lastName) {
        tempBin.tickets.push(value);
      }
    });
    if (this.anonBin) {
      tempBin.user = null;
    } else {
      tempBin.user = this.bin.user as UserWithBoxOfficeDto;
    }
    if (this.promoCodePrice) {
      tempBin.promoCode = this.promoCode;
    }
    tempBin.comment = '';

    const body = {
      typeOfPayment: tempBin.typeOfPayment,
      comment: tempBin.comment,
      order: tempBin.order,
      promoCode: tempBin.promoCode,
      useBonuse: false,
      user: tempBin?.user
        ? {
            firstName: tempBin.user.firstName,
            lastName: tempBin.user.lastName,
            mail: tempBin.user.mail,
            phone: tempBin.user.phone,
          }
        : null,
    };
    if (!tempBin?.user) {
      delete body.user;
    }
    this.uBinService.finishBin('BOX_OFFICE', +this.orderString, body).subscribe(
      next => {
        if (this.event.abonementEvent) {
          const finishBinAbonementEventsIds = [];
          next.ticketList.forEach(ticket => ticket.abonementEvents.forEach(event => finishBinAbonementEventsIds.push(event.id)));
          finishBinAbonementEventsIds.sort();
          this.finishBinAbonementEventsId = finishBinAbonementEventsIds[0];
        }
        // this.orderString = '';
        this.loaderService.endLoader();
        this.changeWay(1);
        (<HTMLInputElement>document.getElementById('all_print_tickets')).checked = true;
        this.changeTicketInBinForPrint(true);
        if (this.timeBuilder) {
          this.timeBuilder.destroy();
        }
        this.sideInfoRxService.add(new SideInfo().setInfo('Замовлення успішно куплено!').setDate());
        // this.router.navigateByUrl('/');
      },
      error => {
        this.loaderService.endLoader();
        this.sideInfoRxService.add(new SideInfo().setInfo('Сталась помилка!').setDate().setError(''));
        console.error(error);
      },
    );

    // this.bookingService.clientOrderBuyBin(tempBin)
  }

  changeTicketInBinForPrint(all: boolean, i?: number, idTicket?: number) {
    if (all) {
      this.ticketsForPrint = [];
      this.newBin.ticketList.forEach((value, index) => {
        if ((<HTMLInputElement>document.getElementById('all_print_tickets')).checked) {
          this.ticketsForPrint.push(value.id);
          (<HTMLInputElement>document.getElementById('all_print_tickets_' + index)).checked = true;
        } else {
          this.ticketsForPrint = [];
          (<HTMLInputElement>document.getElementById('all_print_tickets_' + index)).checked = false;
        }
      });
    } else {
      if ((<HTMLInputElement>document.getElementById('all_print_tickets_' + i)).checked == false) {
        (<HTMLInputElement>document.getElementById('all_print_tickets_' + i)).checked = false;
        this.ticketsForPrint = this.ticketsForPrint.filter(value => value != idTicket);
        (<HTMLInputElement>document.getElementById('all_print_tickets')).checked = false;
      } else {
        (<HTMLInputElement>document.getElementById('all_print_tickets_' + i)).checked = true;
        this.ticketsForPrint.push(idTicket);
        if (this.ticketsForPrint.length == this.newBin.ticketList.length) {
          (<HTMLInputElement>document.getElementById('all_print_tickets')).checked = true;
        }
      }
    }
  }

  printTickets(typePrint: 'TERM' | 'A4' | 'BIG') {
    this.loaderService.startLoader();
    if (this.ticketsForPrint.length == 0) {
      this.sideInfoRxService.add(new SideInfo().setInfo('Позначте які квитки для друку!').setDate().setError(''));
      this.loaderService.endLoader();
      return;
    }
    if (typePrint == 'TERM') {
      this.pdfService.generatePdfTerm(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
          this.ticketsForPrint.forEach(value => {
            this.ticketsForPrinted.add(value);
          });
        },
        error => {
          this.loaderService.endLoader();
          console.error(error);
        },
      );
    } else if (typePrint == 'BIG') {
      this.pdfService.generateBigPdfTerm(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
          this.ticketsForPrint.forEach(value => {
            this.ticketsForPrinted.add(value);
          });
        },
        error => {
          this.loaderService.endLoader();
          console.error(error);
        },
      );
    } else {
      this.pdfService.generatePdfA4(this.ticketsForPrint).subscribe(
        next => {
          let mediaType = 'application/pdf';
          let blob = new Blob([next], { type: mediaType });
          this.printDocument('ticket-document', URL.createObjectURL(blob));
          this.ticketsForPrint.forEach(value => {
            this.ticketsForPrinted.add(value);
          });
        },
        error => {
          this.loaderService.endLoader();
          console.error(error);
        },
      );
    }
  }

  printDocument(documentId, path) {
    this.printPdf(path);
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.wayStepRange <= -40 && this.ticketsForPrinted.size < this.bin.ticketList.length) {
      return confirm('Ви дійсно бажаєте повернутись на головну?');
    } else {
      return true;
    }
  }

  printPdf(url) {
    let iframe;
    iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = () => {
      setTimeout(() => {
        this.loaderService.endLoader();
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  startValidEmail() {
    this.loaderService.startLoader();
  }

  outputValidEmail(validStatus: boolean) {
    if (validStatus === null) {
      this.loaderService.startLoader();
      return;
    }
    this.validEmail = validStatus;
    this.loaderService.endLoader();
  }

  usePromoCode() {
    if (this.promoCode) {
      this.bookingService.usePromoCode(this.promoCode, this.orderString, this.isActivePromoCode).subscribe(
        res => {
          if (res.response && res.response !== 'false') {
            alert(`Промокод ${this.isActivePromoCode ? 'де' : ''}активовано`);
            this.isActivePromoCode = !this.isActivePromoCode;
            this.loadBin();
          } else {
            alert('Промокод не може бути застосований');
          }
        },
        err => {
          alert('Промокод не валідний');
        },
      );
    }
  }

  removePromoCode() {
    this.promoCode = '';
    this.promoCodePrice = 0;
  }

  private createBin(): Observable<string> {
    if (this.orderString) {
      return of(this.orderString);
    }
    const abonementsBinsTime = sessionStorage.getItem('abonementsBinsTime');
    const unlockSeconds =
      (this.abonementCode || this.abonementId) && abonementsBinsTime ? (new Date(abonementsBinsTime).valueOf() - Date.now()) / 1000 : null;
    return this.uBinService.createBin('BOX_OFFICE', this.eventId, null).pipe(
      tap(() => {
        if ((this.abonementCode || this.abonementId) && !unlockSeconds) {
          sessionStorage.setItem('abonementsBinsTime', new Date(Date.now() + this.event.ticketOrderTimeBoxOffice * 1000).toString());
        }
        this.timeBuilder = new TimerBuilder()
          .setSecond(unlockSeconds || this.event.ticketOrderTimeBoxOffice)
          .onEnd(() => {
            alert('час корзини вичерпаний');
            this.bin = new Bin();
            this.bin.user = new UserWithBoxOfficeDto();
            this.newBin = null;
            this.orderString = '';
            ['previousBinInfo', 'abonementsBinsTime', 'abonementEventIds', 'pickedAbonements'].forEach(el => sessionStorage.removeItem(el));
            window.location.reload();
          })
          .buildFromNow();
      }),
    );
  }

  removeTicketFromBin(ticket: IBinTicket) {
    this.uBinService.unlockTicket('BOX_OFFICE', +this.orderString, this.eventId, [ticket.id]).subscribe(() => {
      this.loadBin();
    });
  }

  newSelectSeats(event: IOnSelectTicketEvent): void {
    let isLocketTiket = false;
    if (event.ticketsIds) {
      isLocketTiket = this.newBin ? !!this.newBin.ticketList.find(el => el.id === event.ticketsIds[0]) : false;
    }
    if (this.abonementCode || (this.abonementId && !isLocketTiket)) {
      if (
        !this.availableAbonements.abonements.length ||
        this.pickedAbonements[this.currentEventAbonementIndex].tickets.length === this.availableAbonements.abonements.length
      ) {
        alert('Доступні квитки на цій події вичерпано');
        return;
      }
      const validTickets = this.tickets.filter(
        el => event.ticketsIds.includes(el.id) && el.colorPrice.price <= this.availableAbonements.maxAbonementPrice,
      );
      if (
        event.ticketsIds
          ? validTickets.length !== event.ticketsIds.length
          : this.ticketGroupWithoutSchema.find(el => el.id === event.ticketGroupId).colorPrice.price >
            this.availableAbonements.maxAbonementPrice
      ) {
        alert('Обрані квитки не відповідають ціновим параметрам абонементу');
        return;
      }
    }
    if (this.wayStepRange !== -80) {
      this.createBin().subscribe(res => {
        this.orderString = res;

        if (event.fanzoneKey) {
          event.ticketsIds = this.newBin.ticketList.filter(el => el.seat.key === event.fanzoneKey).map(el => el.id);
        } else if (event.ticketGroupId) {
          event.ticketsIds = [this.ticketsDto.filter(el => el.ticketGroupWithOutSachem.id === event.ticketGroupId)[0].id];
        }
        if (this.abonementCode || this.abonementId) {
          event.ticketsIds = [event.ticketsIds[0]];
        }

        this.uBinService[isLocketTiket ? 'unlockTicket' : 'lockTicket']('BOX_OFFICE', +res, this.eventId, event.ticketsIds).subscribe(
          () => {
            this.loadBin(false, {
              isLockTicket: isLocketTiket,
              ticketId: event.ticketsIds[0],
              ticketGroup: this.ticketGroupWithoutSchema.find(el => el.id === event.ticketGroupId),
            });
          },
        );
      });
    }
  }

  private loadBin(
    isFirstTime?: boolean,
    abonementAdditionalEventsDate?: { ticketId: number; isLockTicket: boolean; ticketGroup: ITicketGrop & { eventsCount?: number } },
  ): void {
    if (this.abonementCode || this.abonementId) {
      this.loadAbonementBin();
    } else {
      const previousBinInfo: { eventId: number; dateEnd: number; order: string } = JSON.parse(sessionStorage.getItem('previousBinInfo'));
      if (
        !this.orderString &&
        previousBinInfo &&
        this.event.id === previousBinInfo.eventId &&
        previousBinInfo.dateEnd > Date.now() &&
        previousBinInfo.order
      ) {
        this.orderString = previousBinInfo.order;
      }

      if (this.orderString) {
        this.uBinService.getBin('BOX_OFFICE', this.orderString, this.promoCode).subscribe(bin => {
          if (isFirstTime) {
            console.log('isFirstTime');
            if (bin.ticketList && !!this.partHall) {
              bin.ticketList.forEach(ticket => {
                const seat = document.getElementById(ticket.seat.key);
                seat.style.fill = this.myColor1;
                seat.setAttribute('seatId', String(ticket.seat.id));
                seat.setAttribute('ticketId', String(ticket.id));
              });
            }
            this.timeBuilder = new TimerBuilder()
              .setSecond((previousBinInfo.dateEnd - Date.now()) / 1000)
              .onEnd(() => {
                this.bin = new Bin();
                this.bin.user = new UserWithBoxOfficeDto();
                this.newBin = null;
                this.orderString = '';
                sessionStorage.removeItem('previousBinInfo');
              })
              .buildFromNow();
          }
          this.newBin = bin;
          this.loaderService.endLoader();
          if (this.event.abonementEvent) {
            const ticketWithNotSelectedEvents = this.newBin.ticketList.find(el => !el.abonementEvents.length);
            if (ticketWithNotSelectedEvents) {
              const minTicketPrice = ticketWithNotSelectedEvents.colorPrice.price;
              this.abonementService.getAbonementPrice(this.eventId).subscribe(res => {
                const eventsCount = res.find(el => el.colorPrice.id === ticketWithNotSelectedEvents.colorPrice.id).eventsCount;
                if (eventsCount) {
                  this.getAdditionalEvents({ eventsCount, ticketId: ticketWithNotSelectedEvents.id, minTicketPrice });
                }
              });
            }
          }

          sessionStorage.setItem(
            'previousBinInfo',
            JSON.stringify({ eventId: bin.event.id, dateEnd: new Date(bin.timeUnlock).valueOf(), order: bin.order }),
          );

          if (
            abonementAdditionalEventsDate &&
            !abonementAdditionalEventsDate.isLockTicket &&
            this.event.abonementEvent &&
            abonementAdditionalEventsDate.ticketGroup.eventsCount
          ) {
            this.getAdditionalEvents({
              eventsCount: abonementAdditionalEventsDate.ticketGroup.eventsCount,
              ticketId: abonementAdditionalEventsDate.ticketId,
              minTicketPrice: this.newBin.ticketList.find(el => el.id === abonementAdditionalEventsDate.ticketId).colorPrice.price,
            });
          }
        });
      } else {
        this.loaderService.endLoader();
      }
    }
  }

  ngOnDestroy(): void {
    ['previousBinInfo', 'abonementsBinsTime', 'abonementEventIds', 'pickedAbonements'].forEach(el => sessionStorage.removeItem(el));

    try {
      if (this.timeBuilder) {
        this.timeBuilder.destroy();
      }
      if (this.orderString) {
        this.binService.deleteBin(this.orderString);
      }

      this.webSocket.disconnect();
      this.subSolidLock.unsubscribe();
      this.subSolidUnlock.unsubscribe();
      this.subWithoutLock.unsubscribe();
      this.subWithoutUnlock.unsubscribe();
    } catch (e) {}
  }
}
