<ng-container *ngIf="currentEvent">
  <div class="container">
    <div class="filter_container">
      <div class="filter_container_info">
        <p>{{ user.firstName + ' ' + user.lastName }}</p>
      </div>
    </div>
    <ng-container *ngIf="currentEvent">
      <div class="title_container" *ngIf="currentEvent.name">
        <div>
          <a class="main-h2" routerLink="/" href="">
            <svg style="width: 17px !important; height: 17px !important" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.0444 5.72693L16.0709 5.73273H4.69532L8.2714 2.14877C8.44652 1.9738 8.54257 1.73676 8.54257 1.48798C8.54257 1.2392 8.44652 1.00383 8.2714 0.828439L7.71496 0.271724C7.53999 0.0967479 7.30683 0 7.05818 0C6.8094 0 6.5761 0.096057 6.40113 0.271033L0.271036 6.40057C0.0953696 6.57624 -0.000687354 6.81023 3.70287e-06 7.05915C-0.000687354 7.30945 0.0953696 7.54358 0.271036 7.71897L6.40113 13.8491C6.5761 14.0239 6.80926 14.1201 7.05818 14.1201C7.30683 14.1201 7.53999 14.0238 7.71496 13.8491L8.2714 13.2923C8.44652 13.1176 8.54257 12.8843 8.54257 12.6356C8.54257 12.3869 8.44652 12.1659 8.2714 11.9911L4.65496 8.38708H16.0571C16.5695 8.38708 17 7.9455 17 7.43342V6.64603C17 6.13396 16.5568 5.72693 16.0444 5.72693Z"
                fill="#3473AE"
              />
            </svg>
            {{ 'events.event_title' | translate }}
          </a>
          <span class="title_container_span">
            {{ currentEvent.name | valueByLang : lang }}
          </span>
        </div>
        <div class="info_main" *ngIf="currentEvent.platformHall">
          <p class="info_main_text">
            {{ currentEvent.platformHall.city.dictionary | valueByLang : lang }}
          </p>
          <div class="info_container_date">
            <p class="info_container_date_up">
              {{ currentEvent.dataBegin | localdate }}
            </p>
            <p class="info_container_date_down">
              {{ currentEvent.dataBegin | localtime }}
            </p>
          </div>
          <p class="info_main_text">
            {{ currentEvent.platformHall.name | valueByLang : lang }}
          </p>
          <p class="info_container_date_up">
            <span *ngFor="let one of currentEvent.eventCategory">
              <span>{{ one.name | valueByLang : lang }}</span>
            </span>
          </p>
        </div>
      </div>
      <div class="content-wrapper">
        <div class="container_legend" [class.disable]="!partHall">
          <span class="circle_container" [ngStyle]="{ color: SELECTED_COLOR }">
            <span class="circle_container_one" [ngStyle]="{ 'background-color': SELECTED_COLOR }"></span>
            {{ 'buy.your_ticket' | translate }}
          </span>
          <span *ngFor="let one of colorPrices" class="circle_container" [ngStyle]="{ color: one.color }" style="margin-right: 20px">
            <span class="circle_container_one" [ngStyle]="{ 'background-color': one.color }"></span>
            {{ one.price }}
          </span>
          <div class="select-types">
            <button [ngClass]="{ current: selectType === 'seat' }" (click)="selectType = 'seat'">Місце</button>
            <button [ngClass]="{ current: selectType === 'row' }" (click)="selectType = 'row'">Ряд</button>
            <button [ngClass]="{ current: selectType === 'sector' }" (click)="selectType = 'sector'">Сектор</button>
          </div>

          <div class="discounts" *ngIf="eventDiscounts[0]">
            <span class="discount-list" [ngStyle]="{ 'text-align': isSameEventDiscountExcludes ? 'center' : 'start' }">
              <div *ngIf="eventDiscounts.length" class="discount-title">
                Діють такі знижки:
                <br />
              </div>
              <div *ngFor="let discount of eventDiscounts" class="discount">
                {{ discount.everyCounter }}
                {{ discount.everyCounter < 5 ? 'квитки' : 'квитків' }} -{{ discount.discount * 100 }}%
              </div>
            </span>
            <span class="discount-list" *ngIf="!isSameEventDiscountExcludes" style="text-align: end">
              <div class="discount-title">Не діють на квитки номіналом:</div>
              <div *ngFor="let discount of eventDiscounts" class="discount">
                <span *ngFor="let exludedPrice of discount.exludedPrices; index as i">
                  {{ exludedPrice }} грн{{ i + 1 == discount.exludedPrices.length ? '.' : ',' }}
                </span>
              </div>
            </span>

            <br />
            <div *ngIf="isSameEventDiscountExcludes && eventDiscounts[0]" class="discount-pass">
              Не діють на квитки номіналом:
              <br />
              <span *ngFor="let exludedPrice of eventDiscounts[0].exludedPrices; index as i">
                {{ exludedPrice }} грн{{ i + 1 == eventDiscounts[0].exludedPrices.length ? '.' : ',' }}
              </span>
            </div>
          </div>

          <button
            *ngIf="hallAction === 'block'"
            (click)="isChangeStatusMode = !isChangeStatusMode"
            [ngClass]="{ current: isChangeStatusMode }"
            class="change-status"
          >
            Перевести в запрошення
          </button>
        </div>
        <div class="hall-container" [ngClass]="{ full: checkWithSheme || routerGo }">
          <app-event-hall
            *ngIf="partHall; else noSchemaHall"
            [partHallPattern]="partHall.pattern"
            [tickets]="tickets"
            [selectType]="selectType"
            [isMultiHall]="partHall?.multiHall"
            (onSelectTickets)="selectSeats($event)"
          ></app-event-hall>
          <ng-template #noSchemaHall>
            <app-event-no-schema [tickets]="ticketGroups" (onSelectTicket)="selectSeats($event)"></app-event-no-schema>
          </ng-template>
        </div>
        <div class="container_bin">
          <app-event-bin
            (onUserPromoCode)="promoCodeCheck($event)"
            [currentBin]="bin"
            [lang]="lang"
            [isActivePromocode]="isActivePromocode"
            (removeTicket)="removeTicketFromBin($event)"
            (binSave)="saveBin()"
          >
            <a class="back-nav" (click)="navigateAway()">Повернутись до списку</a>
            <ng-container [ngSwitch]="hallAction">
              <ng-container *ngSwitchCase="'order'">
                <ng-container *ngTemplateOutlet="vendorSelection"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'invitations'">
                <ng-container *ngTemplateOutlet="inviteForm"></ng-container>
              </ng-container>
            </ng-container>
          </app-event-bin>
        </div>

        <div *ngIf="partHall?.multiHall && hallAction === 'block'" class="container_bin second-bin">
          <app-event-bin
            [currentBin]="secondBin"
            [lang]="lang"
            (removeTicket)="removeTicketFromSecondBin($event)"
            (binSave)="saveSecondBin()"
          >
            <a class="back-nav" (click)="navigateAwaySecondBin('invitations')">Повернутись до списку</a>
            <ng-container [ngSwitch]="hallAction">
              <ng-container *ngTemplateOutlet="inviteForm"></ng-container>
            </ng-container>
          </app-event-bin>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #vendorSelection>
  <div class="form-item">
    <label for="vendorSelector">Змінити контрагента:</label>
    <select
      *ngIf="vendorList; else loader"
      name="vendorSelector"
      [formControl]="selectVendorForm"
      (change)="selectVendor(+$event.target.value)"
    >
      <option *ngFor="let vendor of vendorList" [value]="vendor.id">
        {{ vendor?.fullName }}
      </option>
    </select>
    <ng-template #loader>
      <div class="spin-wrapper">
        <nz-spin nzSimple></nz-spin>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #inviteForm>
  <form class="main_form">
    <div>
      <label *ngIf="invitedUser" style="color: green; text-align: center">
        данні автоматично заповнено.
        <br />
        користувач з такою поштою вже існює.
        <br />
        <span *ngIf="invitedUser.lastName">{{ invitedUser.lastName }}</span>
        <span *ngIf="invitedUser.firstName">{{ invitedUser.firstName }}</span>
        <span *ngIf="invitedUser.middleName">{{ invitedUser.middleName }}</span>
        ({{ invitedUser.role }})
      </label>
      <div class="container_input">
        <label>
          <span>{{ 'event.event-invitation.name' | translate }}</span>
          <br />
          <input
            [disabled]="invitedUser"
            [ngClass]="{ invalid_input: !checkValidData }"
            (input)="checkValidData = true"
            [(ngModel)]="tempUser.firstName"
            [ngModelOptions]="{ standalone: true }"
            type="text"
          />
        </label>
        <label>
          <span>{{ 'event.event-invitation.lastname' | translate }}</span>
          <br />
          <input
            [disabled]="invitedUser"
            [ngClass]="{ invalid_input: !checkValidData }"
            (input)="checkValidData = true"
            [(ngModel)]="tempUser.lastName"
            [ngModelOptions]="{ standalone: true }"
            type="text"
          />
        </label>
      </div>
      <label>
        <span>{{ 'event.event-invitation.email' | translate }}</span>
        <br />
        <input
          [ngClass]="{ invalid_input: !checkValidData }"
          autocomplete
          (change)="tempUser.mail.toLocaleLowerCase(); selectInvitedUser($event)"
          (timeInput)="findUserByEmail($event)"
          [time]="500"
          (input)="checkValidData = true"
          [(ngModel)]="tempUser.mail"
          [ngModelOptions]="{ standalone: true }"
          type="text"
          list="mail_invite_select_data_list"
        />
        <datalist id="mail_invite_select_data_list" *ngIf="invitedUsers">
          <option *ngFor="let user of invitedUsers.content" value="{{ user.mail }}"></option>
        </datalist>
      </label>
      <label>
        <span>{{ 'event.event-invitation.note' | translate }}</span>
        <br />
        <input
          [ngClass]="{ invalid_input: !checkValidData }"
          (input)="checkValidData = true"
          [(ngModel)]="invitationComment"
          [ngModelOptions]="{ standalone: true }"
          type="text"
        />
      </label>
      <div class="show-price-item">
        <label for="showPrice">Показувати ціну</label>
        <input [checked]="showPrice" (change)="showPrice = !showPrice" name="showPrice" type="checkbox" />
      </div>
    </div>
  </form>
</ng-template>

<nz-modal
  [(nzVisible)]="isVendorModalVisible"
  nzTitle="Виберіть контрагента"
  [nzWidth]="400"
  nzCloseIcon=""
  [nzOkLoading]="isVendorContentLoading"
  (nzOnCancel)="onVendorSelectionCancel()"
  [nzOkDisabled]="!vendorList"
  (nzOnOk)="selectVendor(+selectedVendor.id)"
>
  <div *nzModalContent>
    <nz-spin nzTip="Завантажую..." [nzSpinning]="!vendorList">
      <nz-select [(ngModel)]="selectedVendor" [nzAllowClear]="true">
        <nz-option *ngFor="let vendor of vendorList" [nzLabel]="vendor.fullName" [nzValue]="vendor"></nz-option>
        <nz-option nzLabel="Default Value" nzValue="Default" nzHide></nz-option>
      </nz-select>
    </nz-spin>
  </div>
</nz-modal>

<nz-modal
  [(nzVisible)]="isPrintModalVisible"
  nzTitle="перелік запрошень:"
  [nzMaskClosable]="false"
  (nzOnCancel)="isPrintModalVisible = false; navigateSecondBin()"
  [nzWidth]="700"
>
  <ng-template nzModalContent>
    <app-tickets-modal
      [tickets]="secondBin.ticketList"
      [orderId]="secondOrderId"
      actionMode="invite"
      [simplePreview]="false"
      [eventId]="null"
    ></app-tickets-modal>
  </ng-template>
  <div *nzModalFooter class="actions-modal-footer"></div>
</nz-modal>
